/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  /* Debounce */
  Function.prototype.debounce = function(threshold){
    var callback = this;
    var timeout;
    return function() {
      var context = this, params = arguments;
      window.clearTimeout(timeout);
      timeout = window.setTimeout(function() {
          callback.apply(context, params);
      }, threshold);
    };
  };


  function panelRoller(){
    var parallaxElements = $('.panel-scroll .background'),
    parallaxQuantity = parallaxElements.length;

    $(window).on('scroll load', function () {

      window.requestAnimationFrame(function () {

        for (var i = 0; i < parallaxQuantity; i++) {
          var currentElement = parallaxElements.eq(i);

          var offset = currentElement.parent().offset().top;
          var scrolled = $(window).scrollTop();
          var windowHeight = 0;
          var opacity = 1;

          currentElement.css({
            'transform': 'translate3d(0,' + Math.round(((scrolled - offset - windowHeight) * 0.33)) + 'px, 0)',
            'opacity': opacity
          });
        }
      });

    });
  }
  function openSubmenu(item) {
    if(!$('.nav__toggle').is(':visible')) { // disable for mobile view
      $(".menu-item-has-children").not(item).find('.sub-menu').attr({
        'aria-hidden': 'true',
        'aria-expanded': 'false'
      });
      $(item).find('.sub-menu').attr({
        'aria-hidden': 'false',
        'aria-expanded': 'true'
      });
    }
  }
  function closeSubmenu(item) {


    if(!$('.nav__toggle').is(':visible')) { // disable for mobile view

      $(item).find('.sub-menu').attr({
        'aria-hidden': 'true',
        'aria-expanded': 'false'
      });
    }
  }



  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        var openMenu = function() {
          $('body').attr({
            'data-nav-visible': 'true'
          });
          $('.nav__toggle').attr({
            'aria-expanded': 'true'
          });
        };

        var closeMenu = function() {
          $('body').attr({
            'data-nav-visible': 'false'
          });
          $('.nav__toggle').attr({
            'aria-expanded': 'false'
          });
        };

        $('.nav__toggle').on('click', function(e) {
          e.preventDefault();
          if($(this).attr('aria-expanded') === 'true'){
            closeMenu();
          }
          else{
            openMenu();
          }
        });

        $('.nav__primary .sub-menu').attr({
          'role': 'menu',
          'aria-hidden': 'true',
          'aria-expanded': 'false'
        });
        var $wrapper = $( '.nav__primary' );
        $('.nav__primary li.menu-item-has-children > a').on('click', function(e) {
          e.preventDefault();
          if($('.nav__toggle').is(':visible')) { //  for mobile view
            if($(this).hasClass('open')){
              $(this).removeClass('open').siblings('.sub-menu').attr({
                'aria-hidden': 'true',
                'aria-expanded': 'false'
              }).slideUp();
            }
            else{
              $wrapper.find( '.sub-menu' ).each( function() {
                $('.banner .nav__main').find('.menu-item-has-children .open').removeClass('open');
                $(this).attr({
                  'aria-hidden': 'true',
                  'aria-expanded': 'false'
                }).slideUp();
              } );
              $(this).addClass('open').siblings('.sub-menu').attr({
                'aria-hidden': 'false',
                'aria-expanded': 'true'
              }).slideDown();
            }
          }
          else{
            $(this).blur(); // remove Click focus
          }
    			//$(this).unbind('click');
    		});

        $(".menu-item-has-children").on("mouseover", function() {
        	openSubmenu(this);
        }).on("mouseleave", function() {
          closeSubmenu(this);
        });

        // Scroll detection for nav styling
        var mainnav = $( '.top__nav' );
        var subnav = $( '.sub__nav' );
        var removeFixin;
        var submenuPosition = function(){
          if($('.hero').length){
            mainnavHeight = 50;
            subnavHeight = $('.hero').height() - $('.banner').outerHeight() - $('.sub__nav').outerHeight();
            navBottom = $(window).height() - $('.hero').height() - 30;
            $('.sub__nav').css({'bottom': navBottom});
          }
          else{
            mainnavHeight = 100;
            subnavHeight = 100;
          }
          mainnav.affix({
            offset: {
              top: function() { return mainnavHeight; }
            }
          });
          subnav.affix({
            offset: {
              top: function() { return subnavHeight; }
            }
          });
          //console.log(subnavHeight);
        };

        mainnav.on( 'affixed.bs.affix', function () {
          if ( removeFixin ){
            clearTimeout(removeFixin);
          }

          removeFixin = setTimeout(function(){
            submenuPosition();
          }, 300);
        } );
        subnav.on( 'affixed.bs.affix', function () {
          if ( removeFixin ){
            clearTimeout(removeFixin);
          }

          removeFixin = setTimeout(function(){
            submenuPosition();
          }, 300);
        } );
        var debouncedsubMenu = submenuPosition.debounce(50);
        var descrolledsubMenu = submenuPosition.debounce(150);
        submenuPosition();
        $(window).on('resize', debouncedsubMenu);
        $(window).on('scroll', descrolledsubMenu);
        /*if(!$('.hero').length){
          wrapTop = $('.banner').outerHeight() + $('.sub__nav').outerHeight();
          $('.wrap').css({'margin-top': wrapTop});
        }*/

        // Hero slideshow
        var slider = $('.hero__slideshow');
        var evenorodd = 0;
        $('.hero__slideshow').imagesLoaded( { background: '.hero__photo' }, function() {
          $('.hero .loader').fadeOut();
          slider.slick({
            fade: true,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            pauseOnFocus: false,
            infinite: true,
            dots: false,
            arrows: true,
            speed: 800,
          });
          $('.hero__photo').css({'opacity': '1'});
        });
        slider.on('init', function(event, slick){
          if (($('.hero__slideshow .slick-slide').length > 1)) {
            $('.slick-slide[data-slick-index="0"]').addClass('burnsy');
            evenorodd = evenorodd + 1;
          }
        });
        slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
          evenorodd = evenorodd + 1;
          if (evenorodd === 1) {
            $('.slick-slide[data-slick-index=' + nextSlide + ']').addClass('burnsy');
          }
          else{
            evenorodd = 0;
            $('.slick-slide[data-slick-index=' + nextSlide + ']').addClass('burnsout');
          }
        });
        slider.on('afterChange', function(event, slick, currentSlide){
          $(".slick-slide:not(.slick-active)").removeClass('burnsy burnsout');
        });

        panelRoller();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'page_template_template_layout': {
      init: function() {
        if($('.tab .content').length){
          $('.tab .content').matchHeight({
            property: 'min-height'
          });
          $.fn.matchHeight._afterUpdate = function(resize, groups) {

            maxheight = $('.tab .content').eq(0).outerHeight() + 2;
            // Plus 2 for border width

            $('.section__details .tabs').css('min-height', maxheight + 'px');
            $('.tab .content').css('height', '100%');
          };
          $('.tabs .tab').on('mouseover', function(e) {
            e.preventDefault();
            $('.tab').removeClass('active-tab');
            $(this).addClass('active-tab');
          });
        }
        $('.staff__member .sqrbtn').on('click', function(e) {
          e.preventDefault();
          $('.staff__member').removeClass('active');
          $(this).parent().addClass('active');
        });
        $('.staff__bio button').on('click', function(e) {
          e.preventDefault();
          $('.staff__member').removeClass('active');
        });
        $('.faq>h3').on('click', function(e) {
          e.preventDefault();
          if($(this).hasClass('open')){
            $(this).removeClass('open');
            $('.answer').slideUp();
          }
          else{
            $('.faqs').find( '.faq' ).each( function() {
              $('.question').removeClass('open');
              $('.answer').slideUp();
            } );
            $(this).addClass('open').siblings('.answer').slideDown({
              complete: function(){
                var offset = $(this).offset();
                if(offset){
                  var scrollto = offset.top - 320;
                  $('html, body').animate({scrollTop:scrollto},
                    {
                       duration: 100,
                       complete: function(){
                         $(window).scroll(); // trigger scroll event to ensure nav stays hidden
                      }
                  });
                }
              }
            });
          }
    		});
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
